import type { NuxtApp } from '#app';
import type { RuntimeConfig } from 'nuxt/schema';

import { z } from 'zod';

export const cardInfoSchema = z.object({
  id: z.string().min(1),
  number: z.string().min(1),
  expire: z.string().optional(),
  default: z.boolean(),
  cardholder_name: z.string().optional(),
});
export type CardInfo = z.infer<typeof cardInfoSchema>;

export class CardInfoController {
  constructor(
    private readonly auth: NuxtApp['$auth'],
    private readonly config: RuntimeConfig,
    private readonly paymentMethodCode: string,
    private readonly position: {
      locationId: string;
      positionName: string;
    } | null = null,
  ) {}

  get url() {
    if (this.position) {
      return `/locations/${this.position.locationId}/${this.position.positionName}/dgft_card_info/${this.paymentMethodCode}/`;
    }
    return `/dgft_card_info/${this.paymentMethodCode}/`;
  }

  async getCardList() {
    return await $fetch<CardInfo[]>(this.url, {
      method: 'GET',
      headers: {
        Authorization: this.auth.tokenStrategy.token?.get().toString() ?? '',
        'Content-Type': 'application/json',
      },
      baseURL: this.config.public.BASE_URL,
      parseResponse: (txt) => z.array(cardInfoSchema).parse(JSON.parse(txt)),
    });
  }

  async deleteCard(cardId: string) {
    return await $fetch(this.url, {
      method: 'DELETE',
      headers: {
        Authorization: this.auth.tokenStrategy.token?.get().toString() ?? '',
        'Content-Type': 'application/json',
      },
      baseURL: this.config.public.BASE_URL,
      body: JSON.stringify({ id: cardId }),
    });
  }
}
